import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

export default function CookiePolicy () {

	const cookieRef = useRef()
	const location = typeof window !== 'undefined' ? window.location.href : ''

	const handleSubmit = ( accepted ) => {
		if ( accepted ) {
			localStorage.setItem( 'gatsby-gdpr-google-analytics', true );
			document.cookie = "gatsby-gdpr-google-analytics=true"
			initializeAndTrack( location )
		} else {
			localStorage.setItem( 'gatsby-gdpr-google-analytics', false );
			document.cookie = "gatsby-gdpr-google-analytics=false"
		}

		cookieRef.current.classList.add( 'is-hidden' )
	}

	const checkCookieOnLoad = () => {
		const cookiePolicyChosen  = localStorage.getItem( 'gatsby-gdpr-google-analytics' )

		if ( !cookiePolicyChosen || ( cookiePolicyChosen !== 'true' && cookiePolicyChosen !== 'false' ) ) {
			cookieRef.current.classList.remove( 'is-hidden' )
		}
	};

	useEffect(() => {
		checkCookieOnLoad()
	}, [] )

	return (
		<section className={`cookie-notice is-hidden `} ref={ cookieRef }>
			<div className="wrapper">
				<div className="cookie-notice__body">
					<div className="cookie-notice__text">
						<h4 className="cookie-notice__title">Cookies on We Are The Trend</h4>
						<p className="cookie-notice__message">We use cookies to give you the best possible online experience. If you continue, we’ll assume you are happy for your web browser to receive all cookies from our website. See our <Link className="cookie-notice__link" to="/cookie-policy/">cookie policy</Link> for more information on cookies and how to manage them.</p>
					</div>
					<div className="cookie-notice__button-container">
						<button className="cookie-notice__button button button--no-margin button--primary"
							value={ true }
							onClick={ () => handleSubmit( true ) }>
							Accept
						</button>
						<button className="cookie-notice__button button button--no-margin button--primary"
							value={ true }
							onClick={ () => handleSubmit( false ) }>
							Decline
						</button>
					</div>
				</div>
			</div>
		</section>
	)
}