import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'gatsby'

import Navigation from '../Navigation/Navigation'

import WATTLogo from '../../images/watt-logo.png'

export default function Header () {

	const RESIZE_DEBOUNCE = 150;
	const headerRef = useRef()

	const getWidth = () => headerRef.current.offsetWidth

	const [ menuToggled, toggleMenu ] = useState( false )
	const [ width, setWidth ] = useState( 0 )


	useEffect( () => {
		if ( headerRef ) {
			setWidth( headerRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] );

	return (
		<header className="header" ref={ headerRef }>

			<div className="header__container">

				<Link to="/" className="header__title-link" aria-label={ 'Home' }>
					<img src={ WATTLogo } className="header__logo" alt="We are the Trend logo" />
					We are the Trend
				</Link>

				<div className="header__navigation-container">
					<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />
				</div>

				<button className="header__mobile-menu-toggle u-show-tablet" onClick={ () =>toggleMenu( !menuToggled ) }>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

			</div>
		</header>
	)
}
