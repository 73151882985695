import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import SubNavigation from './SubNavigation'

export default function Navigation( props ) {

	const { toggleMenu, menuToggled, width } = props

	const queryData = useStaticQuery(graphql`
		query NavQuery {
			site {
				siteMetadata {
					menuLinks {
						name
						link
						subLinks {
							name
							link
						}
					}
				}
			}
		}
	`)

	const data = queryData.site.siteMetadata.menuLinks

	return (
		<div className={`navigation ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( ( navItem, index ) => (
							<React.Fragment key={ index }>
								{ navItem?.subLinks?.length ? (
									<SubNavigation navItem={ navItem } toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />
								) : (
									<li className="navigation__item">
										<Link
											to={ `/#${ navItem.link }` }
											className="navigation__link"
											activeClassName="active"
											onClick={ () => toggleMenu( false ) } >
											{ navItem.name }
										</Link>
									</li>
								) }
							</React.Fragment>
						) ) }
					</ul>
				</div>
			</nav>
		</div>
	)
}