import React from 'react'

import WATTLogo from '../../images/watt-logo.png'
import CDOLogo from '../../images/svgs/cdo-logo.svg'
import IconLinkedIn from '../../images/svgs/icon-linkedIn.svg'
import IconInstagram from '../../images/svgs/icon-instagram.svg'

export default function Footer() {

	return (
		<footer className="footer">
			<div className="footer__wrapper">
				<div className="footer__content-container">
					<div className="footer__content footer__content--social-container">
						<a href="https://www.instagram.com/wearethetrendcreative/" className="footer__social-link" target="_blank" rel="noreferrer">
							<IconInstagram className="footer__social-icon" />
						</a>
						<a href="https://www.linkedin.com/company/we-are-the-trend/" className="footer__social-link" target="_blank" rel="noreferrer">
							<IconLinkedIn className="footer__social-icon" />
						</a>
						<div className="footer__social-links-container">
							<a href="mailto:hello@wearethetrend.com" className="footer__link">hello@wearethetrend.com</a>
							<a href="tel:02036331741" className="footer__link">02036331741</a>
						</div>
					</div>
					<div className="footer__content footer__content--logo-container">
						<img src={ WATTLogo } className="footer__logo" alt="We are the Trend logo" />
						<span className="footer__logo-title">We are the Trend</span>
					</div>
					<div className="footer__content footer__content--meta-container">
						<div className="footer__meta-content">
							<div className="footer__meta-text">Developed by:</div>
							<a href="https://www.cdodigital.com" className="footer__meta-logo-link" target="_blank" rel="noreferrer">
								<CDOLogo className="footer__meta-logo" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
